<template>
  <section class="iq-style3">
    <b-container class="pt-4 pb-4">
      <b-row class="mb-5 p-3 d-flex justify-content-between">
        <curva-title :title="$t('main.arrivals')"></curva-title>
        <router-link :to="{name: 'newArrivals'}">
          <b-button class="curva-transparent-btn">{{ $t('main.viewAll') }}</b-button></router-link>
      </b-row>
      <b-row class="mb-5">
        <b-col class="text-center">
          <swiper id="arrivalSwiper" :options="swiperOptions" v-if="arrivals.length > 0">
            <swiper-slide v-for="(product, key) in arrivals" :key="key">
              <product-card :product="product" />
            </swiper-slide>
          </swiper>
          <b-spinner v-else variant="warning" type="grow" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  props: {
    arrivals: {
      type: Array
    }
  },
  data () {
    return {
      swiperOptions: {
        centeredSlides: false,
        loop: false,
        // slidesPerView: 4.5,
        // spaceBetween: 10,
        autoplay: false,
        breakpoints: {
          // when window width is >= 480px
          0: {
            slidesPerView: 1.2,
            spaceBetween: 15
          },
          640: {
            slidesPerView: 1.2,
            spaceBetween: 15
          },
          767: {
            slidesPerView: 2.2,
            spaceBetween: 30
          },
          991: {
            slidesPerView: 3.8,
            spaceBetween: 30
          }
        }
      }
    }
  }
}
</script>
