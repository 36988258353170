<template>
  <section class="cover" :style="{ 'background-image': 'url(' + homeCarouselImage + ')' }">
<!--    <img :src="homeCarouselImage" class="cover-image">-->
    <div class="overlay">
      <div class="carousel-container">
        <swiper id="home-swiper" :options="swiperOptions" :pagination="true" class="overflow-hidden">
          <swiper-slide>
            <div class="d-flex justify-content-start">
            <span class="tag offer-tag mb-3 d-inline-block">{{ $t('main.offer') }}</span>
            </div>
            <p class="cover-title mb-1 md:mb-4 text-initial">{{$t('slider.first')}}</p>
            <div class="d-flex justify-content-start gap_1">
              <router-link :to="{name: 'offers'}">
                <b-button class="curva-transparent-btn">{{ $t('main.more') }}</b-button></router-link>
              <router-link :to="{name: 'search', query: {q : '2024/25'}}">
                <b-button class="curva-granola-btn rounded-btn ml-3">{{ $t('main.buy') }}</b-button></router-link>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="d-flex justify-content-start">
              <span class="tag offer-tag mb-3 d-inline-block">{{ $t('main.offer') }}</span>
            </div>
            <p class="cover-title mb-1 md:mb-4 text-initial">{{$t('slider.second')}}</p>
            <div class="d-flex justify-content-start gap_1">
              <router-link :to="{name: 'offers'}">
                <b-button class="curva-transparent-btn">{{ $t('main.more') }}</b-button></router-link>
              <router-link :to="{name: 'categories', params: {category: '2'}}">
                <b-button class="curva-granola-btn rounded-btn ml-3">{{ $t('main.buy') }}</b-button></router-link>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="d-flex justify-content-start">
              <span class="tag offer-tag mb-3 d-inline-block">{{ $t('main.offer') }}</span>
            </div>
            <p class="cover-title mb-1 md:mb-4 text-initial">{{$t('slider.third')}}</p>
            <div class="d-flex justify-content-start gap_1">
              <router-link :to="{name: 'offers'}">
                <b-button class="curva-transparent-btn">{{ $t('main.more') }}</b-button></router-link>
              <router-link :to="`/categories/all-categories?brand=9`">
                <b-button class="curva-granola-btn rounded-btn ml-3">{{ $t('main.buy') }}</b-button></router-link>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    banners: { type: Array }
  },
  data () {
    return {
      swiperOptions: {
        centeredSlides: false,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: true,
        breakpoints: {
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 30
          }
        },
        // If we need pagination
        pagination: {
          el: '.swiper-pagination'
        }
      },
      homeCarouselImage: require('@/assets/images/curva/main-cover.png'),
      slide: 0,
      sliding: null
    }
  }
}
</script>
<style>
@media only screen and (max-width: 500px) {
  .cover, .cover-image{
    min-height: 60vh !important;
  }
  .carousel-container {
    padding: 0px !important;
    left: 6%;
  }
  .cover-title {
    font-size: 20px;
  }
  .cover-text {
    font-size: 15px;
  }
  .curva-transparent-btn, .curva-granola-btn {
    height: 40px !important;
    padding: 4px 15px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    border-radius: 10px !important;
  }
  .offer-tag {
    background-color: var(--iq-blush);
    color: white;
  }
}
</style>
