<template>
  <section>
    <b-container class="pt-2 pb-2">
      <b-row class="d-flex justify-content-center mb-3 p-3 pt-4">
        <curva-title :title="$t('main.explore')"></curva-title>
      </b-row>
      <b-row class="mb-5 row-gap">
        <b-col
          lg="2"
          cols="6"
          v-for="(category, key) in categories"
          :key="key"
        >
          <category-card :category="category" />
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  data () {
    return {
      categories: [
        {
          title: this.$t('mainNav.footballWear'),
          image: require('@/assets/images/curva/categories-images/footballWear.png'),
          router: '1'
        },
        {
          title: this.$t('mainNav.sportsShoes'),
          image: require('@/assets/images/curva/categories-images/shoes.png'),
          router: '2'
        },
        {
          title: this.$t('mainNav.football'),
          image: require('@/assets/images/curva/categories-images/football.png'),
          router: '6'
        },
        {
          title: this.$t('mainNav.clothing'),
          image: require('@/assets/images/curva/categories-images/clothing.png'),
          router: '4'
        },
        {
          title: this.$t('mainNav.sportsBags'),
          image: require('@/assets/images/curva/categories-images/bags.png'),
          router: '5'
        },
        {
          title: this.$t('mainNav.accessories'),
          image: require('@/assets/images/curva/categories-images/accessories.png'),
          router: '3'
        }
      ]
    }
  }
}
</script>
