<template>
  <section>
    <b-container class="pt-4 pb-4 text-center">
      <b-row class="mb-5 p-3 d-flex justify-content-between">
        <curva-title :title="$t('main.blogs')"></curva-title>
        <router-link :to="{name: 'blogs'}">
          <b-button class="curva-transparent-btn">{{ $t('main.viewAll') }}</b-button></router-link>
      </b-row>
      <b-row v-if="blogs.length > 0">
        <b-col class="text-center">
          <swiper id="blogsSwiper" :options="swiperOptions">
            <swiper-slide v-for="(blog, key) in blogs" :key="key">
              <blog-card :blog="blog"></blog-card>
            </swiper-slide>
          </swiper>
        </b-col>
      </b-row>
      <b-spinner class="mb-5" v-else variant="warning" type="grow" label="Spinning"></b-spinner>
    </b-container>
  </section>
</template>
<script>
export default {
  props: {
    blogs: {
      type: Array
    }
  },
  data () {
    return {
      swiperOptions: {
        centeredSlides: false,
        loop: false,
        // slidesPerView: 4.5,
        // spaceBetween: 10,
        autoplay: false,
        breakpoints: {
          // when window width is >= 480px
          0: {
            slidesPerView: 1.1,
            spaceBetween: 15
          },
          767: {
            slidesPerView: 2.2,
            spaceBetween: 30
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }
      }
    }
  }
}
</script>
